<template>
<div class="home">
    <audio :src="sound" preload="auto"></audio>
    <div class="canv-holder">
        <canvas id="canvasExp" ref="canvasExp"></canvas>
        <canvas id="canvas" ref="canvas"></canvas>
    </div>
    <input autocomplete="off" name="hide" value="" type="text" ref="inpt" class="inpu-mob" @keydown="playSound" @keyup.prevent="type">
    <a href="" @click="showButton=false" target="_blank" ref="fb"><span :class="['fb-button', {'open': showButton}]"><img src="/fbshare1.png" alt=""></span></a>
</div>
</template>

<script>
const soundTypewriter = '/typewriter1.mp3';
const soundComputer = '/computer1.mp3';
const soundMobile = '/mobile1.mp3';
const soundMac = '/keyboard1.mp3';

import canvasTxt from '../misc/canvasTxt';

export default {
    name: 'Home',
    props: ['answers', 'epohe', 'getEpohe'],
    components: {},
    computed: {},
    created() {
    },
    data() {
        return {
            question: '',
            epoha: '',
            answersSet: [],
            isWriting: false,
            answer: '',
            i: 0,
            j: 0,
            lengthLimit: 2000,
            contenteditable: false,
            canvas: null,
            canvasExp: null,
            context: null,
            contextExp: null,
            imageObj: null,
            textWidth: null,
            fontSize: 18,
            font: "",
            mainText: 'Pitaj Isaka Deusa \n _________________ \n\n',
            sound: '',
            imageSrc: '',
            fontUrl: '',
            fontColor: '',
            fontWeight: '300',
            rowsLimit: 12,
            fromTop: 0,
            fromLeft: 0,
            width: 0,
            height: 0,
            fontStyle: 'normal',
            caret: '',
            boldFirst: false,
            focused: true,
            carretIntervalMain: null,
            carretRestart: null,
            started: null,
            audio: null,
            isIOS: false,
            showButton: false,
        };
    },
    mounted() {
        this.start();
        var body = document.body;
        body.classList.add("hide-ovr");
        var root = document.documentElement;
        root.classList.add('hide-ovr');
    },
    unmounted() {
        var body = document.body;
        body.classList.remove("hide-ovr");
        var root = document.documentElement;
        root.classList.remove('hide-ovr');
    },
    methods: {
        start() {

            this.showButton = false;

            this.question = '';
            this.isWriting = false;
            this.answer = '';
            this.i = 0;
            this.j = 0;
            this.contenteditable = false;
            this.mainText = 'Pitaj Isaka Deusa \n _________________ \n\n';
            this.caret = '';
            this.boldFirst = false;
            this.focused = true;
            if (this.carretRestart) clearInterval(this.carretRestart);
            if (this.$refs.inpt) this.$refs.inpt.value = '';

            let self = this;

            if (this.$refs.inpt) this.$refs.inpt.focus();

            this.epoha = this.getEpohe;

            if (!localStorage.answers) {
                localStorage.answers = '';
            } else {
                this.answersSet = Object.values(JSON.parse(localStorage.answers));
            }

            if (this.epoha === 'epoha1') {
                this.sound = soundTypewriter;
                this.imageSrc = '/epoha1.jpg';
                this.fontUrl = '/fonts/1942report.woff';
                this.font = 'Typewriter';
                this.fontSize = 24;
                this.fontColor = "#000000";
                this.rowsLimit = 7;
                this.fromTop = 140;
                this.fromLeft = 190;
                this.width = 380;
                this.height = 400;
            } else if (this.epoha === 'epoha2') {
                this.sound = soundComputer;
                this.imageSrc = '/epoha2.jpg';
                this.fontUrl = '/fonts/PressStart2P.woff';
                this.font = 'oldcomputer';
                this.fontSize = 18;
                this.fontColor = "#7dc4d6";
                this.rowsLimit = 12;
                this.fromTop = 160;
                this.fromLeft = 200;
                this.width = 420;
                this.height = 400;
            } else if (this.epoha === 'epoha3') {
                this.sound = soundMobile;
                this.imageSrc = '/epoha3.jpg';
                this.fontUrl = '/fonts/nokia1.woff';
                this.font = 'nokia1';
                this.fontSize = 23;
                this.fontColor = "#000000";
                this.mainText = 'Pitaj Isaka Deusa \n _____________ \n';
                this.rowsLimit = 6;
                this.fromTop = 120;
                this.fromLeft = 140;
                this.width = 280;
                this.height = 400;
            } else if (this.epoha === 'epoha4') {
                this.sound = soundMac;
                this.imageSrc = '/epoha4.jpg';
                this.fontUrl = '/fonts/Arial.woff';
                this.font = 'arial';
                this.fontSize = 28;
                this.fontColor = "#000000";
                this.mainText = 'Pitaj Isaka Deusa \n';
                this.rowsLimit = 6;
                this.fromTop = 100;
                this.fromLeft = 180;
                this.width = 390;
                this.height = 400;
                this.fontStyle = 'italic';
                this.boldFirst = 'Pitaj Isaka Deusa';
            }

            this.audio = new Audio(this.sound);

            var epohaFont = new FontFace('LoadFont', 'url(' + this.fontUrl + ')');

            if (!this.started) {

                window.onload = function () {

                    self.canvas = document.getElementById("canvas");
                    self.context = self.canvas.getContext("2d");

                    self.imageObj = new Image();
                    self.imageObj.onload = function () {

                        self.canvas.width = this.naturalWidth;
                        self.canvas.height = this.naturalHeight;
                        self.canvas.font = this.font;
                        self.context.drawImage(self.imageObj, 0, 0, self.imageObj.width, self.imageObj.height, 0, 0, self.canvas.width, self.canvas.height);

                        document.fonts.ready.then(() => {

                            epohaFont.load().then(function (font) {
                                self.started = true;
                                document.fonts.add(font);
                                canvasTxt.font = self.font;
                                self.wrapText(self.context, '', true);
                                self.showCaret();
                            });

                        });
                    }

                    self.imageObj.src = self.imageSrc;

                };


            } else {
                self.wrapText(self.context, '', true);
                self.showCaret();
            }

            if (document.readyState === "complete") {

                self.canvas = document.getElementById("canvas");
                self.context = self.canvas.getContext("2d");

                self.imageObj = new Image();
                self.imageObj.onload = function () {

                    self.canvas.width = this.naturalWidth;
                    self.canvas.height = this.naturalHeight;
                    self.canvas.font = this.font;
                    self.context.drawImage(self.imageObj, 0, 0, self.imageObj.width, self.imageObj.height, 0, 0, self.canvas.width, self.canvas.height);

                    document.fonts.ready.then(() => {

                        epohaFont.load().then(function (font) {
                            self.started = true;
                            document.fonts.add(font);
                            canvasTxt.font = self.font;
                            self.wrapText(self.context, '', true);
                            self.showCaret();
                        });

                    });
                }

                self.imageObj.src = self.imageSrc;

            }

            window.addEventListener("focus", () => {
                self.focused = true;
            });

            window.addEventListener("blur", () => {
                self.focused = false;
                if (this.audio) this.audio.pause();
            });

            this.isIOS = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        },
        showCaret() {

            let self = this;

            this.carretIntervalMain = setInterval(() => {
                if (self.j % 2 === 0) this.wrapText(this.context, self.question + '|');
                else
                    this.wrapText(this.context, self.question);
                self.j++;
            }, 500);

        },
        showCaretRestart() {

            this.j = 0;

            let self = this;

            this.carretRestart = setInterval(() => {
                if (self.j % 2 === 0) this.wrapText(self.context, self.question + '\n\n' + self.answer);
                    else
                        this.wrapText(self.context, self.question + '\n\n' + self.answer);
                self.j++;
            }, 500);

        },
        removeDiacritics(input) {
            var output = "";

            var normalized = input.normalize("NFD");
            var i = 0;
            var j = 0;

            while (i < input.length) {
                output += normalized[j];

                j += (input[i] == normalized[j]) ? 1 : 2;
                i++;
            }

            return output;
        },
        typeWriter(txt) {

            let self = this;

            clearInterval(self.carretIntervalMain);

            let speeds = [50, 75, 100, 125, 150];
            let speed = speeds[Math.floor(Math.random() * speeds.length)];

            if (this.i < txt.length) {
                this.answer += txt.charAt(this.i);
                if (this.focused) this.playSound();
                let textSend;
                let carret = '';
                if (this.epoha !== 'epoha1') carret = '|';
                textSend = this.question + '\n\n' + this.answer + carret;
                this.wrapText(this.context, textSend);
                this.i++;
                setTimeout(() => {
                    self.typeWriter(txt);
                }, speed);
            } else if (this.i === txt.length) {
                self.showCaretRestart();
                this.isWriting = false;
                if (this.$refs.inpt) this.$refs.inpt.style.display = 'inline-block';
                if (this.$refs.inpt) this.$refs.inpt.focus();
                this.showButton = true;
                if (this.focused) this.share();
            }

        },
        playSound(event) {
            if ((event && event.shiftKey) || this.isIOS) return;
            if (this.audio) {
                this.audio.currentTime = 0;
            }
            this.audio.play();
        },
        getAnswer() {

            this.isWriting = true;

            let answer = this.answers[Math.floor(Math.random() * this.answers.length)];

            while (this.answersSet.includes(answer) && this.answersSet.length < this.answers.length) {
                answer = this.answers[Math.floor(Math.random() * this.answers.length)];
            }

            this.answersSet.push(answer);
            localStorage.answers = JSON.stringify(Object.assign({}, this.answersSet));

            this.typeWriter(answer);

        },
        wrapText(context, text, start = false) {

            var self = this;

            if (!start) text = this.mainText + text;

            self.context.drawImage(self.imageObj, 0, 0, self.imageObj.width, self.imageObj.height, 0, 0, self.canvas.width, self.canvas.height);

            self.context.fillStyle = this.fontColor;

            canvasTxt.font = this.font;
            canvasTxt.fontSize = this.fontSize;
            canvasTxt.align = 'left';
            canvasTxt.vAlign = 'top';
            canvasTxt.fontWeight = this.fontWeight;
            canvasTxt.fontStyle = this.fontStyle;

            canvasTxt.drawText(self.context, text, self.canvas.width / 2 - this.fromLeft, self.canvas.height / 2 - this.fromTop, this.width, this.height, this.rowsLimit, this.boldFirst)

        },
        getKeyCode(str) {
            return str.charCodeAt(str.length - 1);
        },
        type(event) {

            let self = this;

            if (this.isWriting) return;

            if (this.answer) {
                this.start();
                return;
            }

            let charCode = (event.keyCode ? event.keyCode : event.which);

            if (charCode == 0 || charCode == 229) charCode = this.getKeyCode(event.target.value);

            if (charCode === 13) {
                if (!this.question) return;
                if (this.answer) {
                    this.start();
                    this.wrapText(self.context, '');
                    return;
                }
                this.$refs.canvas.focus();
                if (this.$refs.inpt) this.$refs.inpt.style.display = 'none';
                this.getAnswer();
                return;
            } else {
                if (this.question.length < this.lengthLimit) this.question = event.target.value;
                this.wrapText(self.context, this.question);
            }

        },
        share() {

            let self = this;

            self.canvasExp = document.getElementById("canvasExp");
            self.contextExp = self.canvasExp.getContext("2d");

            self.canvasExp.width = 1000;
            self.canvasExp.height = 525;

            self.contextExp.drawImage(self.canvas, (self.canvas.width - 1000) / 2 , (self.canvas.height - 525) / 2, 1000, 525, 0, 0, 1000, 525);
            let image = self.canvasExp.toDataURL('image/jpeg', 0.7);

            let ajax = new XMLHttpRequest();

            ajax.open("POST", "https://europe-automobile.com/share/share.php", false);
            ajax.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

            ajax.onreadystatechange = function() {

                let data = JSON.parse(ajax.responseText);

                let shareUrl = 'https://www.facebook.com/dialog/share?'+
                'app_id=793698124806666'+
                '&display=popup'+
                '&caption=Pitaj Isaka Deusa' +
                //'&href=' + location.href +
                //'&media=["' + location.href + data.image + '"]' +
                '&media=["https://europe-automobile.com/' + data.image + '"]' +
                //'&picture=' + location.href + data.image +
                '&picture=https://europe-automobile.com/' + data.image +
                '&description=https://isakdeus.rs' +
                '&link=https://isakdeus.rs/' +
                '&redirect_uri=https://isakdeus.rs/';

                self.$refs.fb.href = shareUrl;

            }

            ajax.send("image=" + image);

        }
    },
    watch: {
        $route: function() {
            this.focused = false;
            if (this.audio) this.audio.pause();
        }
    }
}

</script>
