<template>
    <div class="recenzije">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <h1>Recenzije</h1>
                    <h2>{{activeReview.title}}</h2>
                    <p class="big">{{activeReview.sub_title}}</p>
                    <div class="recenzije__content">
                        <img src="/quote.png" class="quote hide-mobile" alt="">
                        <p v-html="activeReview.html">
                        </p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <SidebarReviews @load-review="loadReview" :reviewsData="reviewsData" :filter="false" limit="4" :socialLinks="socialLinks" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { reviews } from '../services/Api';
import SidebarReviews from '../components/SidebarReviews';

export default {
    name: 'Recenzije',
    props: ['socialLinks'],
    components: {
        SidebarReviews
    },
    data() {
        return {
            page: 1,
            perPage: 10,
            loading: false,
            reviewsData: [],
            id: null,
        }
    },
    mounted() {
        this.getReviews();
        let body = document.body;
        body.classList.add("black");
    },
    computed: {
        activeReview() {
            let review = {};

            if (this.reviewsData.length) {
                if (this.id===null) {
                    review = this.reviewsData[0];
                } else {
                    for (var i=0; i < this.reviewsData.length; i++) {
                        if (this.reviewsData[i].id === this.id) {
                            return review = this.reviewsData[i];
                        }
                    }
                }
            }

            return review
        }
    },
    methods: {
        loadReview(id) {
            this.id = id;
        },
        getReviews() {
            reviews(this.page, this.perPage)
                .then(res => {
                    if (res.status) {
                        this.reviewsData = this.reviewsData.concat(res.reviews);
                        this.loading = false;
                    }
                });
            this.page++;
        }
    },
    unmounted() {
        document.querySelector('body').classList.remove("black");
    }
}

</script>

