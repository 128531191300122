<template>
    <div class="sidebar">
        <div class="sidebar__article" v-for="(post, index) in showPosts" :key="`sidebar-author-post-${index}`">
            <div class="sidebar__background hide-desktop" :style="{backgroundImage: 'url(' + post.image + ')'}"></div>
            <p class="sidebar__date hide-desktop">{{setDate(post.date)}}</p>
            <h3>{{post.title}}</h3>
            <hr>
            <p class="sidebar__date hide-mobile">{{setDate(post.date)}}</p>
            <p class="sidebar__content">{{setString(post.html)}}</p>
            <a href="#" @click.prevent="smoothScroll('header');$router.push('/post/' + post.url)">Čitaj dalje ></a>
        </div>
        <div class="footer-social hide-desktop">
            <a target="_blank" :href="socialLinks.fb"><img src="/fbf.png" alt=""></a>
            <a target="_blank" :href="socialLinks.in"><img src="/instf.png" alt=""></a>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Sidebar',
    props: ['postsData', 'filter', 'limit', 'socialLinks'],
    computed: {
        showPosts() {

            let self = this;

            let sidebarPosts = [];

            if (!this.filter) {

                sidebarPosts = this.postsData;

            } else {
                sidebarPosts = this.postsData.filter(function( obj ) {
                    return obj.url !== self.$route.params.slug;
                });
            }

            if (this.limit) sidebarPosts = sidebarPosts.slice(0, this.limit);

            return sidebarPosts;
        }
    },
    methods: {
        currentYPosition() {
            // Firefox, Chrome, Opera, Safari
            if (self.pageYOffset) return self.pageYOffset;
            // Internet Explorer 6 - standards mode
            if (document.documentElement && document.documentElement.scrollTop)
                return document.documentElement.scrollTop;
            // Internet Explorer 6, 7 and 8
            if (document.body.scrollTop) return document.body.scrollTop;
            return 0;
        },
        elmYPosition(eID) {
            var elm = document.getElementById(eID);
            var y = elm.offsetTop;
            var node = elm;
            while (node.offsetParent && node.offsetParent != document.body) {
                node = node.offsetParent;
                y += node.offsetTop;
            } return y;
        },
        smoothScroll(eID) {
            let i;
            this.menu = false;
            var startY = this.currentYPosition();
            var stopY = this.elmYPosition(eID);
            var distance = stopY > startY ? stopY - startY : startY - stopY;
            if (distance < 100) {
                scrollTo(0, stopY); return;
            }
            var speed = Math.round(distance / 10);
            if (speed >= 20) speed = 20;
            var step = Math.round(distance / 25);
            var leapY = stopY > startY ? startY + step : startY - step;
            var timer = 0;
            if (stopY > startY) {
                for (i=startY; i<stopY; i+=step ) {
                    setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                    leapY += step; if (leapY > stopY) leapY = stopY; timer++;
                } return;
            }
            for (i=startY; i>stopY; i-=step ) {
                setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
            }
        },
        setString(str) {

            let div = document.createElement("div");
            div.innerHTML = str;
            let text = div.textContent || div.innerText || "";

            text = text.substring(0, 130);
            text = text.trim();
            text = text + '...';
            return text;
        },
        setDate(dataTime) {

            var t = new Date(dataTime);

            let date = parseInt(t.getDate());

            if (date < 10) {
                date = '0' + date;
            }

            let month = parseInt(t.getMonth()) + 1;

            if (month < 10) {
                month = '0' + month;
            }

            let year = t.getFullYear();

            return date + '.' + month + '.' + year + '.';

        },
    }
}

</script>