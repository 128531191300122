<template>
    <div class="blog">
        <div class="wrapper" id="blogContent">
            <div class="blog__content" v-for="(post, index) in postsData" :key="`posts-${index}`" @click.prevent="smoothScroll('header');$router.push('/post/' + post.url)">
                <div class="container">
                    <div class="blog__article">
                        <div class="row arcticles-blog">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                                <div class="article__image" :style="{ backgroundImage: `url(${post.image})` }"></div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6">
                                <div class="article__content">
                                    <p class="date-mobile hide-desktop">{{setDate(post.date)}}</p>
                                    <h2>{{post.title}}</h2>
                                    <hr>
                                    <p class="date hide-mobile">{{setDate(post.date)}}</p>
                                    <p v-html="setString(post.html)"></p>
                                    <a href="#" @click.prevent="smoothScroll('header');$router.push('/post/' + post.url)">ČITAJ DALJE ></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="spinner-div" v-if="loading">
                <font-awesome-icon :icon="['fas', 'spinner']" spin  />
            </div>
            <div class="footer-social hide-desktop">
                <a target="_blank" :href="socialLinks.fb"><img src="/fbf.png" alt=""></a>
                <a target="_blank" :href="socialLinks.in"><img src="/instf.png" alt=""></a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Blog',
    props: ['postsData', 'socialLinks', 'loading'],
    components: {},
    methods: {

        currentYPosition() {
            // Firefox, Chrome, Opera, Safari
            if (self.pageYOffset) return self.pageYOffset;
            // Internet Explorer 6 - standards mode
            if (document.documentElement && document.documentElement.scrollTop)
                return document.documentElement.scrollTop;
            // Internet Explorer 6, 7 and 8
            if (document.body.scrollTop) return document.body.scrollTop;
            return 0;
        },
        elmYPosition(eID) {
            var elm = document.getElementById(eID);
            var y = elm.offsetTop;
            var node = elm;
            while (node.offsetParent && node.offsetParent != document.body) {
                node = node.offsetParent;
                y += node.offsetTop;
            } return y;
        },
        smoothScroll(eID) {
            let i;
            this.menu = false;
            var startY = this.currentYPosition();
            var stopY = this.elmYPosition(eID);
            var distance = stopY > startY ? stopY - startY : startY - stopY;
            if (distance < 100) {
                scrollTo(0, stopY); return;
            }
            var speed = Math.round(distance / 10);
            if (speed >= 20) speed = 20;
            var step = Math.round(distance / 25);
            var leapY = stopY > startY ? startY + step : startY - step;
            var timer = 0;
            if (stopY > startY) {
                for (i=startY; i<stopY; i+=step ) {
                    setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                    leapY += step; if (leapY > stopY) leapY = stopY; timer++;
                } return;
            }
            for (i=startY; i>stopY; i-=step ) {
                setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
            }
        },
        setDate(dataTime) {

            var t = new Date(dataTime);

            let date = parseInt(t.getDate());

            if (date < 10) {
                date = '0' + date;
            }

            let month = parseInt(t.getMonth()) + 1;

            if (month < 10) {
                month = '0' + month;
            }

            let year = t.getFullYear();

            return date + '.' + month + '.' + year + '.';

        },
        setString(str) {

            var div = document.createElement("div");
            div.innerHTML = str;
            var text = div.textContent || div.innerText || "";

            text = text.substring(0, 130);
            text = text.trim();
            text = text + '...';
            return text;
        }
    },
    mounted() {
        let body = document.body;
        body.classList.add("black");
    },
    unmounted() {
        document.querySelector('body').classList.remove("black");
     }
}
</script>

