<template>
    <div class="autor">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-8">
                    <div class="autor__image"></div>
                    <h1>Ivan Radovanović</h1>
                    <div class="autor__content">
                        <p>
                            <strong>Ivan Radovanović, rođen je 1961. godine u Beogradu. Bio je novinar i urednik u listovima “Politika”, “Borba”, “Vreme”, “Dnevni telegraf”, “Evropljanin”, osnivač i vlasnik dnevnika “Borba”, kao i urednik informativnog programa BK televizije.</strong> Tokom devedesetih izveštavao je o ratnim zbivanjima iz Ljubljane, Sarajeva, Plitivica, Knina, Pakraca, Borovog Sela, Bijeljine, Dubrovnika…
                            Napisao je do sada pet knjiga: “Ništa”, “Kratka istorija života u mrtvom gradu”, “5. oktobar” (sa Draganom Bujoševićem), “Dubrovnik” i “Isak Deus i njegovi izveštaji iz prašine“.
                            Živi u Beogradu, oženjen je i ima petoro dece.
                        </p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                    <Sidebar :postsData="postsData" :filter="false" limit="4" :socialLinks="socialLinks" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Sidebar from '../components/Sidebar';

export default {
    name: 'Autor',
    props: ['postsData', 'socialLinks'],
    components: {
        Sidebar
    },
    methods: {
        setString(str) {

            var div = document.createElement("div");
            div.innerHTML = str;
            var text = div.textContent || div.innerText || "";

            text = text.substring(0, 130);
            text = text.trim();
            text = text + '...';
            return text;
        },
    },
    mounted() {
        let body = document.body;
        body.classList.add("black");
    },
    unmounted() {
        document.querySelector('body').classList.remove("black");
     }
}
</script>

