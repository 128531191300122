<template>
    <div id="header">
        <div class="header">
            <div class="buy-book">
                <ul>
                    <li :class="[{'active': $route.path === '/'}]" @click="$router.push('/')"><router-link to="/">Naslovna</router-link></li>
                    <li :class="[{'active': $route.path === '/autor'}]" @click="$router.push('/autor')"><router-link to="/autor">O autoru</router-link></li>
                    <li :class="[{'active': $route.path === '/blog'}]" @click="$router.push('/blog')"><router-link to="/blog">Blog</router-link></li>
                    <li :class="[{'active': $route.path === '/recenzije'}]" @click="$router.push('/recenzije')"><router-link to="/recenzije">Recenzije</router-link></li>
                    <li :class="[{'active': $route.path === '/knjiga'}]" @click="$router.push('/knjiga')"><router-link to="/knjiga">O knjizi</router-link></li>
                    <li class="book"><a href="https://albionbooks.rs/edicije/nasa-ponuda/ivan-radovanovic-isak-deus-i-njegovi-izvestaji-iz-prasine/" a target="_blank">KUPI KNJIGU</a></li>
                    <li class="viber"><a href="https://stickers.viber.com/pages/custom-sticker-packs/11eb5cbfccd0087e914cf1ce5df4363a3fa68771bbea4ef1" a target="_blank">Deus na Viberu</a></li>
                    <a target="_blank" class="social" href="https://www.facebook.com/Isak-Deus-100740621797056"><img src="../assets/facebook.png" alt=""></a>
                <a target="_blank" class="social ml-0 mr-0" href="https://www.instagram.com/isak.deus/"><img src="../assets/instagram.png" alt=""></a>
                </ul>
                <div class="social-silver">
                    <a target="_blank" :href="socialLinks.fb"><img src="/fbsilver.png" alt=""></a>
                    <a target="_blank" :href="socialLinks.in"><img src="/instsilver.png" alt=""></a>
                </div>
            </div>
        </div>
        <div :class="['tg_nav', {'position-fixed': open}]">
            <div :class="['toggle', {'clicked': open}]" @click="open=!open">
            <div class="bar"></div>
            </div>
        </div>
        <div class="responsive-navigation">
            <div :class="['menu', {'collaps': open===false}, {'expand': open===true}]">
                <div class="nav-hold">
                    <div class="left-menu"></div>
                    <ul class="navigation">
                        <li :class="[{'active': $route.path === '/'}]" @click="$router.push('/'); closeNav()"><router-link to="/">Naslovna</router-link></li>
                        <li :class="[{'active': $route.path === '/autor'}]" @click="$router.push('/autor'); closeNav()"><router-link to="/autor">O autoru</router-link></li>
                        <li :class="[{'active': $route.path === '/blog'}]" @click="$router.push('/blog'); closeNav()"><router-link to="/blog">Blog</router-link></li>
                        <li :class="[{'active': $route.path === '/recenzije'}]" @click="$router.push('/recenzije'); closeNav()"><router-link to="/recenzije">Recenzije</router-link></li>
                        <li :class="[{'active': $route.path === '/knjiga'}]" @click="$router.push('/knjiga'); closeNav()"><router-link to="/knjiga">O knjizi</router-link></li>
                        <li class="book"><a href="https://albionbooks.rs/edicije/nasa-ponuda/ivan-radovanovic-isak-deus-i-njegovi-izvestaji-iz-prasine/" a target="_blank">KUPI KNJIGU</a></li>
                        <li class="viber"><a href="https://stickers.viber.com/pages/custom-sticker-packs/11eb5cbfccd0087e914cf1ce5df4363a3fa68771bbea4ef1" a target="_blank">Deus na Viberu</a></li>
                        <ul class="social-responsive">
                            <a target="_blank" class="social" :href="socialLinks.fb"><img src="../assets/facebook.png" alt=""></a>
                            <a target="_blank" class="social" :href="socialLinks.in"><img src="../assets/instagram.png" alt=""></a>
                        </ul>
                    </ul>
                </div>
            </div>
        </div>
        <div :class="['scroll-top', {'show': scrollY>200}]" @click="smoothScroll('app')"><img src="/scrolltop.png" alt=""></div>
    </div>
</template>

<script>
export default {
    name: 'Header',
    props: ['socialLinks', 'scrollY'],
    data() {
        return {
            open: null,
            changed: null,
        }
    },
    mounted() {
        let self = this;
        document.addEventListener('click', function(e) {

            if (!e.target.classList.contains('responsive-navigation') && !e.target.classList.contains('bar') && !e.target.classList.contains('toggle')) {
                self.open = false;
            }

        }, false);

        document.addEventListener('touchstart', (e) => {

            if (!e.target.classList.contains('responsive-navigation') && !e.target.classList.contains('bar') && !e.target.classList.contains('toggle')) {
                self.open = false;
            }

        });

    },
    unmounted() {

    },
    methods: {
        closeNav() {
            this.open = null;
        },
        currentYPosition() {
            // Firefox, Chrome, Opera, Safari
            if (self.pageYOffset) return self.pageYOffset;
            // Internet Explorer 6 - standards mode
            if (document.documentElement && document.documentElement.scrollTop)
                return document.documentElement.scrollTop;
            // Internet Explorer 6, 7 and 8
            if (document.body.scrollTop) return document.body.scrollTop;
            return 0;
        },
        elmYPosition(eID) {
            var elm = document.getElementById(eID);
            var y = elm.offsetTop;
            var node = elm;
            while (node.offsetParent && node.offsetParent != document.body) {
                node = node.offsetParent;
                y += node.offsetTop;
            } return y;
        },
        smoothScroll(eID) {
            let i;
            this.menu = false;
            var startY = this.currentYPosition();
            var stopY = this.elmYPosition(eID);
            var distance = stopY > startY ? stopY - startY : startY - stopY;
            if (distance < 100) {
                scrollTo(0, stopY); return;
            }
            var speed = Math.round(distance / 10);
            if (speed >= 20) speed = 20;
            var step = Math.round(distance / 25);
            var leapY = stopY > startY ? startY + step : startY - step;
            var timer = 0;
            if (stopY > startY) {
                for (i=startY; i<stopY; i+=step ) {
                    setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                    leapY += step; if (leapY > stopY) leapY = stopY; timer++;
                } return;
            }
            for (i=startY; i>stopY; i-=step ) {
                setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
            }
        },
    }
}
</script>
