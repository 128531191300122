import axios from 'axios';

const baseUrl = 'https://isakdeusbackend.it-fortress.com/api/';
//dev
//const baseUrl = 'http://isakdeus.local:9060/api/';

const postsUrl = `${baseUrl}posts`;
const postUrl = `${baseUrl}post`;
const reviewsUrl = `${baseUrl}reviews`;
const reviewUrl = `${baseUrl}review`;
const settingsUrl = `${baseUrl}settings`;

export const posts = async (page, perPage) => {
  const result = await axios
    .get(postsUrl + '/' + page + '/' + perPage);

  return result.data;
};

export const post = async (slug) => {
  const result = await axios
    .get(postUrl + '/' + slug);

  return result.data;
};

export const settings = async () => {
  const result = await axios
    .get(settingsUrl);

  return result.data;
};

export const reviews = async (page, perPage) => {
  const result = await axios
    .get(reviewsUrl + '/' + page + '/' + perPage);

  return result.data;
};

export const review = async (id) => {
  const result = await axios
    .get(reviewUrl + '/' + id);

  return result.data;
};
