<template>
    <div class="sidebar">
        <div class="sidebar__article" v-for="(review, index) in showReviews" :key="`sidebar-author-post-${index}`">
            <p class="sidebar__date hide-desktop">{{setDate(review.date)}}</p>
            <h4>{{review.title}}</h4>
            <hr>
            <p class="sidebar__date hide-mobile">{{setDate(review.date)}}</p>
            <p class="sidebar__content">{{setString(review.html)}}</p>
            <a href="#" @click.prevent="smoothScroll('header');$emit('load-review', review.id);">Čitaj dalje ></a>
        </div>
        <div class="footer-social hide-desktop">
            <a target="_blank" :href="socialLinks.fb"><img src="/fbf.png" alt=""></a>
            <a target="_blank" :href="socialLinks.in"><img src="/instf.png" alt=""></a>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SidebarReview',
    props: ['reviewsData', 'filter', 'limit', 'socialLinks'],
    computed: {
        showReviews() {

            let self = this;

            let sidebarReviews = [];

            if (!this.filter) {

                sidebarReviews = this.reviewsData;

            } else {
                sidebarReviews= this.reviewsData.filter(function( obj ) {
                    return obj.id !== self.$route.params.id;
                });
            }

            if (this.limit) sidebarReviews = sidebarReviews.slice(0, this.limit);

            return sidebarReviews;
        }
    },
    methods: {
        currentYPosition() {
            // Firefox, Chrome, Opera, Safari
            if (self.pageYOffset) return self.pageYOffset;
            // Internet Explorer 6 - standards mode
            if (document.documentElement && document.documentElement.scrollTop)
                return document.documentElement.scrollTop;
            // Internet Explorer 6, 7 and 8
            if (document.body.scrollTop) return document.body.scrollTop;
            return 0;
        },
        elmYPosition(eID) {
            var elm = document.getElementById(eID);
            var y = elm.offsetTop;
            var node = elm;
            while (node.offsetParent && node.offsetParent != document.body) {
                node = node.offsetParent;
                y += node.offsetTop;
            } return y;
        },
        smoothScroll(eID) {
            let i;
            this.menu = false;
            var startY = this.currentYPosition();
            var stopY = this.elmYPosition(eID);
            var distance = stopY > startY ? stopY - startY : startY - stopY;
            if (distance < 100) {
                scrollTo(0, stopY); return;
            }
            var speed = Math.round(distance / 10);
            if (speed >= 20) speed = 20;
            var step = Math.round(distance / 25);
            var leapY = stopY > startY ? startY + step : startY - step;
            var timer = 0;
            if (stopY > startY) {
                for (i=startY; i<stopY; i+=step ) {
                    setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                    leapY += step; if (leapY > stopY) leapY = stopY; timer++;
                } return;
            }
            for (i=startY; i>stopY; i-=step ) {
                setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
            }
        },
        setString(str) {

            let div = document.createElement("div");
            div.innerHTML = str;
            let text = div.textContent || div.innerText || "";

            text = text.substring(0, 130);
            text = text.trim();
            text = text + '...';
            return text;
        },
        setDate(dataTime) {

            var t = new Date(dataTime);

            let date = parseInt(t.getDate());

            if (date < 10) {
                date = '0' + date;
            }

            let month = parseInt(t.getMonth()) + 1;

            if (month < 10) {
                month = '0' + month;
            }

            let year = t.getFullYear();

            return date + '.' + month + '.' + year + '.';

        },
    }
}

</script>