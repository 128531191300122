<template>
    <div class="singlepost">
        <div class="container">
            <div class="row">
                <div class="col-lg-8" v-if="postData.created_at">
                    <div class="singlepost__image" :style="{ backgroundImage: `url(${postData.image})` }"></div>
                    <h1>{{postData.title}}</h1>
                    <p class="singlepost__date">{{setDate(postData.date)}}</p>
                    <div class="singlepost__content">
                        <img src="/quote.png" class="quote hide-desktop" alt="">
                        <div v-html="postData.html"></div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <Sidebar :postsData="postsData" :filter="false" limit="4" :socialLinks="socialLinks" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { post } from '../services/Api';
import Sidebar from '../components/Sidebar'

export default {
    name: 'SinglePost',
    props: ['postsData', 'socialLinks'],
    components: {
        Sidebar
    },
    mounted() {
        let body = document.body;
        body.classList.add("black");
        this.getPost();
    },
    unmounted() {
        this.postData = {};
        document.querySelector('body').classList.remove("black");
    },
    data() {
        return {
            postData: {}
        }
    },
    methods: {
        getPost() {
            let slug = this.$route.params.slug;
            post(slug)
                .then(res => {
                    if (res.status) {
                        this.postData = res.post;
                    }
                });
        },
        setDate(dataTime) {

            var t = new Date(dataTime);

            let date = parseInt(t.getDate());

            if (date < 10) {
                date = '0' + date;
            }

            let month = parseInt(t.getMonth()) + 1;

            if (month < 10) {
                month = '0' + month;
            }

            let year = t.getFullYear();

            return date + '.' + month + '.' + year + '.';

        },
    },
    watch: {
        $route: function() {
            this.getPost();
        }
    }
}

</script>



















