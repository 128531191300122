<template>
  <div id="app" class="container-fluid m-0 p-0">
    <div :class="['app']" id="inside">
      <Heade :scrollY="scrollY" :socialLinks="socialLinks" />
      <router-view :loading="loading" :socialLinks="socialLinks" :postsData="postsData" :getEpohe="getEpohe" :answers="answers" :epohe="epohe"/>
    </div>
  </div>
</template>

<script>

import Heade from './components/Header';
import { posts, settings } from './services/Api';

export default {
  name: 'App',
  components: {
    Heade
  },
  mounted() {
    let self = this;
    window.onscroll = function() {
      self.scrollY = window.scrollY;
      if(!document.getElementById("blogContent")) return;
      var elementTarget = document.getElementById("blogContent");
      var st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > self.lastScrollTop){
          if (window.scrollY + window.innerHeight >= (elementTarget.offsetTop + elementTarget.offsetHeight)) {
            if (!self.postDebounce) {
              self.getPosts();
              self.postDebounce = true;
              self.loading = true;
              setTimeout(() => { self.postDebounce = false;}, 500);
            }
        }
        self.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      }

    }
    this.getEpohe = this.epohe[Math.floor(Math.random() * this.epohe.length)];
    this.getPosts();
    this.getSettings();
  },
  computed: {
    myProps() {

      if (this.$route.name === 'Blog' || this.$route.name === 'SinglePost') return this.postsData;
      return [];
    }
  },
  methods: {
    getSettings() {
      settings()
          .then(res => {
              if (res.status) {

                  this.settings = res.settings;

                  document.title = this.settings.app_name;
                  var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                  link.type = 'image/x-icon';
                  link.rel = 'shortcut icon';
                  link.href = this.settings.fav_icon;
                  document.getElementsByTagName('head')[0].appendChild(link);

                  document.getElementsByTagName('meta')["description"].content = this.settings.app_desc;

                  this.socialLinks.fb = this.settings.fb_link;
                  this.socialLinks.in = this.settings.in_link;

              }
          });
    },
    getPosts() {
      posts(this.page, this.perPage)
          .then(res => {
              if (res.status) {
                  this.postsData = this.postsData.concat(res.posts);
                  this.loading = false;
              }
          });
      this.page++;
    },
  },
  data() {
    return {
      scrollY: 0,
      loading: false,
      postDebounce: false,
      lastScrollTop: 0,
      page: 1,
      perPage: 10,
      socialLinks: {},
      settings: {},
      postsData: [],
      posts: [],
      getEpohe: null,
      epohe: [ 'epoha1', 'epoha2', 'epoha3', 'epoha4' ],
      answers: [
        'Odgajani ste za mitinge, ne za vođe.',
        'Nije vas baba dovoljno lupala metlom po praznoj glavi.',
        'Trudite se, pa odustanete.',
        'Ništa ne vidite, a stalno tražite.',
        'Koliko bog još svojih smrti može da preživi?',
        'Navikli ste na laž, bez nje ne možete da živite.',
        'Hvala vam što u potrazi i igranju učestvujete.',
        'Kada bi lav i govorio mi ga ne bismo razumeli.',
        'Istorija je razbijena svetlost koja mrak stvara.',
        'Nema rešenja u zagonetkama.',
        'Na pogrešnom mestu tražite.',
        'Bogovi su izmišljeni da bi ih ljudi ubili.',
        'Da li shvataš?',
        'Ti si pogrešan! Kosmos si pomerio unazad umesto da ga širiš.',
        'Nisi ti za kralja. Ne za onog kralja.',
        'Svako dete treba da ima svoju majku.',
        'Ponekad mislim da se sva veličina, u stvari, nalazi u pukoj naivnosti.',
        'Sumnja postaje opšte mesto tek kada si je podelio.',
        'Reči su tu da bi oni bez ljubavi mogli da se zaljube u sebe same.',
        'Reči su uzrok poslušnosti.',
        'Reči su uzrok nikada posledica.',
        'Reči su deca užasa, feldmaršale.',
        'Samo slava kroz reči daje zlu opravdanje.',
        'Moguće je komunicirati očima i kada voliš i kada mrziš.',
        'Molim tebe, a ti zamoli sve druge, za nekoliko vekova ćutanja.',
        'Namigni, trepni, mrdni nosom, ramenom, potapši, klimni glavom, videćeš, svi će te razumeti.',
        'Razmisli. Opameti se. Saberi se. I zabrani. Reči.',
        'Ti bar svaki zvuk koji ispustiš, razumeš.',
        'Dakle, tražite malo, a ne mene da gnjavite, ne moram sve da znam. Kako god.',
        'Ne znaš o čemu pričam, jado moja?',
        'Oh, bezumniče, zašto si to uradio? Stremljenje si obesmislio, zauvek!',
        'Pao si zato što si se peo! A peo si se pre vremena!',
        'Strašnu stvar si uradio, oče svih junaka, ubico svih čudovišta.',
        'Ti si ishodište, i cilj.',
        'Ljudi su čudne životinje, lažu da veruju, veruju da lažu.',
        'O, zar ne vidiš, uz svu maštu?! Da nije moguće!',
        'Koliki put je prešao, od životinje, samo da bi izgubio, čoveka.',
        'Ha, pa možda majmuna ja tražim, ko zna?',
        'Pozdravi ženu, ljubi je u oko, i svaku kćer, veličanstvenost njihovu.',
        'Zato što je danas sve što je bilo, i što treba da bude.',
        'Kada primetiš da te vođa gleda s prezirom, nemoj da sumnjaš, ni tren, da si upravo to i zaslužio.',
        'Ko sme da ćuti, taj ne sme da kaže. Ko sme da kaže, taj bolje da ćuti. Sve između toga je laž.',
        'Glupane, što daješ ime onome što ne postoji?',
        'Reči, kada nisu okasnele, uvek te zajebu.',
        'Svi koji, za života, pričaju, o smrti, posle ćute, u smrti.',
        'Ne možeš samo jednu kuću da izgradiš, a da imaš grad.',
        'Svet menja znanje, a ne menjaju ga revolucije.',
        'Nema tu kraja, samo je početak važan.',
        'Oh, pa zar neznanje nije blagosloveno!',
        'Hajde, ubrzaj malo, skrati, daviš dosta.',
      ]
    }
  }
}
</script>
