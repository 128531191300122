<template>
    <div class="knjiga">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <h1>O knjizi</h1>
                    <h2 class="hide-desktop">Isak Deus, priča o slobodi</h2>
                    <div class="knjiga__image"></div>
                    <h2 class="hide-mobile">Isak Deus, priča o slobodi</h2>
                    <div class="knjiga__content">
                        <p class="font-weight-bold">
                            Isak Deus je nastao usred epidemije korone, ne kao odgovor na nju, nego kao potreba da se, u realnim ograničenjima, ostane slobodan.
                            Slobodan da, bez obzira na sve, stvaraš, misliš, tragaš.
                            Isak Deus je emanacija te potrage, tog zadatka.
                        </p>
                        <p>
                            I nije bitno, u celoj priči, za čime tragaš, bitno je da se, ni jednog trena, u tome ne zaustaviš, krećući se, ili stojeći u mestu, svejedno je. Sloboda je unutrašnje stanje, ne postoji spolja.
                        </p>
                        <p>
                            I Isak radi baš ono što jeste najvažniji zadatak u životu, prema sebi i prema društvu, otkriva sopstvenu slobodu.<br>
                            A biti slobodan znači da posvećeno radiš ono što voliš i želiš, bez izgovora i ugožavanja drugih.
                            Biti slobodan da znaš, naučiš.<br>
                            Biti slobodan da, naoružan znanjem, učenjem, misliš sam.<br>
                            Biti slobodan da veruješ, što je, uvek, posledica svega pobrojanog.<br>
                            Težak proces, dugotrajan, ima svoju cenu, ali rezultat je neprocenjiv (priceless).<br>
                            Zasniva se na pravljenju sopstvenog univerzuma, nezavisnog od okruženja, vremena u kojem živiš, bilo koje vlasti.<br>
                            Omogućava ti da se izdvojiš iz gomile, samim tim i iz parola, fraza, napamet naučenih teza, ponavljanja, kružoka, i svakog straha od toga da li nešto možeš, ili moraš.<br>
                            Možeš, sve, i ne moraš ništa, baš zato što ispituješ, sumnjajući, i svoje mogućnosti i svoja moranja. Sam odlučuješ, biraš, spreman da snosiš odgovornost.<br>
                            I to nema nikakve veze sa anarhijom, haosom, varvarstvom.<br>
                            Naprotiv, slobodan si jer poštuješ pravila, jer si pristojan, spreman za suživot sa drugima, poštujući njihova prava, ne ugrožavajući ih.<br>
                            A opet nisi deo gomile. Izdvajaš se, najpre sopstvenim delom, kojem si posvećen, a onda i mišlju i stavom, kriterijumom, koji iz tog dela potiču.<br>
                            Formirao si ih radeći ono što si odabrao da radiš, dajući sve od sebe, težeći savršenstvu.<br>
                            Svo zlo ovog sveta potiče iz neostvarenosti, odsustva želje za ostvarenošću, i ti, baš zato, za zlo ne znaš.<br>
                            Slobodan si, kada si ostvaren, kada se ostvaruješ.<br>
                            Nezavisno od drugih, bez obzira na njih.<br>
                            Nije ti potrebna potvrda mase, ne moraš da budeš poznat, da budeš deo, bilo čega.<br>
                            A opet se izdvajaš, prepoznatljiva je tvoja različitost, i nosiš je bez ikakvih pretenzija, sujete i narcizma.<br>
                            Različitost, sloboda, jeste tvoje prirodno stanje, baš zato što si je sam izabrao, i nemaš potrebu ni da je deliš, ni razglašavaš, niti da bilo koga teraš da te sledi.<br>
                            Omogućava ti, naprotiv, da razumeš druge, čak i kada ih ne ceniš.<br>
                            Oni su, kao i ti, datost, čije postojanje možda može da utiče na tvoj život, ali, nikada, na tvoj izbor i tvoju slobodu.<br>
                            Ti ne navijaš, ti ne slediš, ti ne ponavljaš.<br>
                            Stojiš sa strane, vidiš, bez priklanjanja, sa jasnim kriterijumom, šta ti se sviđa, a šta ne.<br>
                            Opremljen si, opremio si se, aparatom za mišljenje (a to nije mozak sam, već mozak plus znanje, volja za njim, disciplina da ga stekneš), i umeš da ga koristiš.<br>
                            Strast ti nije argument, ona je samo tu da te gura da argumente tražiš, da učiš, otkrivaš, menjaš, pre svega sebe.<br>
                            Nisi zadovoljan, nikada, sopstvenim znanjem. Ono je, svestan si toga, kod tebe ograničeno, ali je, istovremeno, želja za znanjem, neograničena, stalna.<br>
                        </p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <Sidebar :postsData="postsData" :filter="false" limit="4" :socialLinks="socialLinks" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Sidebar from '../components/Sidebar';

export default {
    name: 'Knjiga',
    props: ['postsData', 'socialLinks'],
    components: {
        Sidebar
    },
    data() {
        return {}
    },
    mounted() {
        let body = document.body;
        body.classList.add("black");
    },
    unmounted() {
        document.querySelector('body').classList.remove("black");
     }
}

</script>





