import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Blog from "@/views/Blog.vue";
import SinglePost from "@/views/SinglePost.vue";
import Autor from "@/views/Autor.vue";
import Knjiga from "@/views/Knjiga.vue";
import Recenzije from "@/views/Recenzije.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: { posts: false }
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/post/:slug",
    name: "SinglePost",
    component: SinglePost,
  },
  {
    path: "/autor",
    name: "Autor",
    component: Autor,
  },
  {
    path: "/knjiga",
    name: "Knjiga",
    component: Knjiga,
  },
  {
    path: "/recenzije",
    name: "Recenzije",
    component: Recenzije,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;